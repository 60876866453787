<template>
	<div>
	<contentHeader title="Data Alat Kesehatan" url="/data-alat-kesehatan" subTitle="Tambah Data Alat Kesehatan" />
	<div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
				<div class="col">
					<div class="card">
						<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Tambah Data Alat Kesehatan</h3>
	            		</div>
	            		<div class="card-body row">
	            			<div class="col-lg-2"></div>
	            			<div class="col-lg-8">
	            				<form @submit.prevent="prosesTambahAlatKesehatan">
		            				<div class="px-lg-2 ">
		            					<div class="form-group">
					                		<label for="kode">Kode Alat Kesehatan </label>
					                		<input type="text" class="form-control" id="kode" name="kode" required="" autocomplete="off" disabled="" v-model="alat_kesehatan.kode">
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Nama <span class="text-danger">*</span></label>
					                		<input type="text" class="form-control" id="nama" name="nama" autocomplete="off" v-model="alat_kesehatan.nama">
					                	</div>
					                	<div class="form-group">
					                		<label for="kategori">Kategori <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="alat_kesehatan.kategori" required="" @change="generateKode()">
					                			<option selected="" hidden=""></option>
					                			<option value="BHP">Bahan Habis Pakai</option>
					                			<option value="POT">POT</option>
					                			<option value="BTL">BOTOL</option>
					                			<option value="NED">NEEDLE</option>
					                			<option value="ABC">ABOCATH</option>
					                			<option value="INF">INFLO</option>
					                			<option value="CAN">CANULLA</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="jenis">Jenis <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="alat_kesehatan.jenis" required="" @change="generateKodeDanGetNomorUrut()">
					                			<option selected="" hidden=""></option>
					                			<option value="alat_kesehatan">Alat Kesehatan</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="fungsi">Fungsi </label>
					                		<textarea class="form-control" v-model="alat_kesehatan.fungsi"></textarea>
					                	</div>
					                	<div class="form-group">
					                		<label for="golongan">Golongan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="alat_kesehatan.golongan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="bahan jadi">Bahan Jadi</option>
					                			<option value="bahan baku">Bahan Baku</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
					                		<label for="nama">Satuan <span class="text-danger">*</span></label>
					                		<select class="custom-select form-control" v-model="alat_kesehatan.satuan" required="">
					                			<option selected="" hidden=""></option>
					                			<option value="pcs">Pcs</option>
					                			<option value="tube">Tube</option>
					                			<option value="ml">Ml</option>
					                			<option value="fls">Fls</option>
					                			<option value="cream">Cream</option>
					                			<option value="vial">Vial</option>
					                			<option value="bungkus">Bungkus</option>
					                			<option value="botol">Botol</option>
					                			<option value="tablet">Tablet</option>
					                			<option value="kaplet">Kaplet</option>
					                			<option value="gram">Gram</option>
					                			<option value="pot">Pot</option>
					                			<option value="strip">Strip</option>
					                			<option value="box">Box</option>
					                			<option value="ampl">Ampl</option>
					                			<option value="cc">CC</option>
					                			<option value="kotak">Kotak</option>
					                			<option value="lembar">Lembar</option>
					                			<option value="kapsul">Kapsul</option>
					                			<option value="kaleng">Kaleng</option>
					                		</select>
					                	</div>
					                	<div class="form-group">
						                    <label>Harga <span class="text-danger">*</span></label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Harga Beli</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_beli" v-model="alat_kesehatan.harga_beli">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Harga Jual</span>
						                    		<div class="input-group mb-3">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">Rp.</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" required="" name="harga_jual" v-model="alat_kesehatan.harga_jual">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<div class="form-group">
					                		<div class="row">
					                			<div class="col">
					                				<label for="minimal_stok">Minimal Stok <span class="text-danger">*</span></label>
					                				<input type="text" class="form-control" id="minimal_stok" name="minimal_stok" autocomplete="off" required="" v-model="alat_kesehatan.minimal_stok">	
					                			</div>
					                			<div class="col">
					                				<label for="maksimal_stok">Maksimal Stok <span class="text-danger">*</span></label>
					                				<input type="text" class="form-control" id="maksimal_stok" required="" name="maksimal_stok" autocomplete="off" v-model="alat_kesehatan.maksimal_stok">
					                			</div>
					                		</div>
					                	</div>
					                	<div class="form-group">
					                		<label for="lokasi">Lokasi <span class="text-danger">*</span></label>
					                		<input type="text" class="form-control" id="lokasi" name="lokasi" required="" autocomplete="off" v-model="alat_kesehatan.lokasi">
					                	</div>
					                	<div class="form-group">
						                    <label>Fee </label>
						                    <div class="row">
						                    	<div class="col">
						                    		<span>Fee Dokter</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_dokter }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_dokter" v-model="alat_kesehatan.fee_dokter" @keyup="changeLabelFeeDokter">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Beautician</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_beautician }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_beautician" v-model="alat_kesehatan.fee_beautician" @keyup="changeLabelFeeBeautician">
									                </div>
						                    	</div>
						                    	<div class="col">
						                    		<span>Fee Sales</span>
						                    		<div class="input-group">
									                  <div class="input-group-prepend">
									                    <div type="button" class="btn btn-secondary">{{ label_fee_sales }}</div>
									                  </div>
									                  <!-- /btn-group -->
						                    		  <input class="form-control" type="number" step="any" name="fee_sales" v-model="alat_kesehatan.fee_sales" @keyup="changeLabelFeeSales">
									                </div>
						                    	</div>
						                    </div>
						                </div>
					                	<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											  <span class="sr-only">Loading...</span>
											</div>
										</button>
		            				</div>
		            			</form>
	            			</div>
	            			<div class="col-lg-2"></div>
	            		</div>
					</div>
				</div>
			</div>
		</div>
	</div>
		
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'

	import { computed, reactive, ref } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'

	const cabang = computed(() => store.getters['auth/cabang'])
	const loading = ref(false)
	const alat_kesehatan = reactive({
		"cabang_id" : cabang.value.id,
		"kode" : "",
		"nama" : "",
		"kategori" : "",
		"jenis" : "",
		"fungsi" : "",
		"golongan" : "",
		"satuan" : "",
		"harga_beli" : "",
		"harga_jual" : "",
		"minimal_stok" : "",
		"maksimal_stok" : "",
		"lokasi" : "",
		"fee_dokter" : "",
		"fee_beautician" : "",
		"fee_sales" : "",
	})

	const prosesTambahAlatKesehatan = () => {
		loading.value = true
		console.log(alat_kesehatan)
		axios.post(`api/produk/create`, alat_kesehatan)
		.then((response) => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil menambahkan data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
			router.push('/data-alat-kesehatan')
			console.log(response)
		})
		.catch((error) => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal menambahkan data',
				icon: 'error',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
			console.log(error)
		})
	}

	const label_fee_dokter = ref('...')

	const changeLabelFeeDokter = () => {
		if (alat_kesehatan.fee_dokter > 100) {
			label_fee_dokter.value = 'Rp. '
		} else if (alat_kesehatan.fee_dokter == '') {
			label_fee_dokter.value = '...'
		} else {
			label_fee_dokter.value = 'Persen (%) '
		}
	}

	const label_fee_beautician = ref('...')

	const changeLabelFeeBeautician = () => {
		if (alat_kesehatan.fee_dokter > 100) {
			label_fee_beautician.value = 'Rp. '
		} else if (alat_kesehatan.fee_beautician == '') {
			label_fee_beautician.value = '...'
		} else {
			label_fee_beautician.value = 'Persen (%) '
		}
	}

	const label_fee_sales = ref('...')

	const changeLabelFeeSales = () => {
		if (alat_kesehatan.fee_dokter > 100) {
			label_fee_sales.value = 'Rp. '
		} else if (alat_kesehatan.fee_beautician == '') {
			label_fee_beautician.value = '...'
		} else {
			label_fee_sales.value = 'Persen (%) '
		}
	}

	const getKode = async () => {
		await axios.get(`api/produk/getForGenerateCode/${cabang.value.kode_cabang}/${cabang.value.tipe}`)
		.then((response) => {
			alat_kesehatan.kode = response.data
		})
	}

	getKode()
</script>